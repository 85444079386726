import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  Box, IconButton, Fade, Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { YouTube as YouTubeIcon } from "@material-ui/icons";

import { Layout, Meta, Section } from "components";
import { Teamspeak as TeamspeakIcon } from "icons";

const useStyles = makeStyles((theme) => ({
  gpnvgImage: {
    height: `${theme.spacing(35)}px !important`,
    margin: "0 auto",
  },
  iconLinks: {
    margin: "0 !important",

    "& > *": {
      margin: theme.spacing(1),
    },
  },
  sectionContainer: {
    paddingBottom: 0,
  },
  sectionWrapper: {
    backgroundColor: "#000",
  },
}));

export const gpnvgImageQuery = graphql`
  query GPNVGImage {
    file(relativePath: { eq: "gpnvg-darkness.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 90,
          width: 800,
          placeholder: NONE
        )
      }
    }
  }`;

const ICON_LINKS = {
  Youtube: {
    icon: <YouTubeIcon />,
    href: "//youtube.com/user/SemtekDivision",
    target: "_blank",
    rel: "noreferrer",
  },
  Teamspeak: {
    icon: <TeamspeakIcon />,
    href: "teamspeak://ts.semtekdivision.com",
  },
};

/**
 * Render index page.
 * @returns {JSX.Element} component
 */
export default function Page() {
  const data = useStaticQuery(gpnvgImageQuery);
  const classes = useStyles();
  const icons = Object.entries(ICON_LINKS)
    .map(([label, linkData]) => {
      const {
        icon,
        href, target, rel,
      } = linkData;
      return (
        <IconButton
          key={label}
          href={href}
          aria-label={label}
          target={target}
          rel={rel}
        >
          { icon }
        </IconButton>
      );
    });
  return (
    <Layout fade={false}>
      <Meta titleTemplate="%s" />
      <Section
        hero
        wrapperClasses={{ root: classes.sectionWrapper }}
        className={classes.sectionContainer}
      >
        <FadeIn delay="0.5s">
          <Typography variant="h1">
            Semtek Division
          </Typography>
        </FadeIn>
        <FadeIn delay="1s">
          <Typography variant="h2">
            A casual gaming community
          </Typography>
        </FadeIn>
        <FadeIn delay="1.5s">
          <Box classes={{ root: classes.iconLinks }}>
            { icons }
          </Box>
        </FadeIn>
        <GatsbyImage
          image={data.file.childImageSharp.gatsbyImageData}
          alt="Soldier with GPNVGs in darkness"
          className={classes.gpnvgImage}
        />
      </Section>
    </Layout>
  );
}

/**
 * Wrap children in fade transition.
 * @param {Object} props props
 * @param {string} props.delay transition delay
 * @param {JSX.Element|Array<JSX.Element>} props.children children
 * @returns {JSX.Element} component
 */
function FadeIn({ delay, children }) {
  return (
    <Fade
      in
      timeout={1000}
      style={{ transitionDelay: delay }}
    >
      { children }
    </Fade>
  );
}
